.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.landing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  min-height: 80vh;
  padding: 2rem 0;
}

.hero-content {
  flex: 1;
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.hero-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 2rem;
}

.app-store-buttons {
  display: flex;
  gap: 1rem;
}

.store-button img {
  height: 50px;
  transition: transform 0.2s;
}

.store-button:hover img {
  transform: scale(1.05);
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-height: 70vh;
  width: auto;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(10px 15px 25px rgba(0, 0, 0, 0.2));
}

.features-section {
  padding: 4rem 0;
}

.features-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.feature-card {
  padding: 2rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.feature-card p {
  color: #666;
  line-height: 1.5;
}

/* Updated Mobile Responsive Design */
@media (max-width: 768px) {
  .landing-container {
    padding: 1rem;
  }

  .hero-section {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
    min-height: auto;
    padding: 1rem 0;
  }

  .hero-content h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .hero-description {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .app-store-buttons {
    justify-content: center;
    flex-wrap: wrap;
  }

  .store-button img {
    height: 40px;
  }

  .hero-image {
    margin: 1rem 0;
  }

  .hero-image img {
    max-height: 50vh;
  }

  .features-section {
    padding: 2rem 0;
  }

  .features-section h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .feature-card {
    padding: 1.5rem;
  }

  .feature-card h3 {
    font-size: 1.2rem;
  }
}

/* Additional Small Screen Optimizations */
@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 1.8rem;
  }

  .app-store-buttons {
    flex-direction: column;
    align-items: center;
  }

  .store-button {
    width: 80%;
    max-width: 200px;
  }
}

.page-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
}

.page-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

/* Support Page Styles */
.support-content {
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.support-section {
  margin-bottom: 2rem;
}

.support-section h2 {
  color: #333;
  margin-bottom: 1rem;
}

.support-section ul {
  list-style: none;
  padding-left: 0;
}

.support-section li {
  margin-bottom: 0.5rem;
  color: #666;
}

.faq-item {
  margin-bottom: 1.5rem;
}

.faq-item h3 {
  color: #444;
  margin-bottom: 0.5rem;
}

/* Terms Page Styles */
.terms-content {
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.terms-section {
  margin-bottom: 3rem;
}

.terms-section h2 {
  color: #333;
  margin-bottom: 1rem;
}

.policy-content h3 {
  color: #444;
  margin: 1.5rem 0 0.5rem;
}

.policy-content ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.policy-content li {
  color: #666;
  margin-bottom: 0.5rem;
}

/* Mobile Responsive Additions */
@media (max-width: 768px) {
  .page-container {
    padding: 1rem;
  }

  .support-content,
  .terms-content {
    padding: 1.5rem;
  }

  .page-container h1 {
    font-size: 1.8rem;
  }

  .support-section h2,
  .terms-section h2 {
    font-size: 1.5rem;
  }

  .faq-item h3,
  .policy-content h3 {
    font-size: 1.2rem;
  }
}

.container {
  max-width: 600px;
  margin: 50px auto;
  text-align: center;
  padding: 20px;
}

.success { 
  color: #28a745; 
}

.error { 
  color: #dc3545; 
}

.message {
  margin: 20px 0;
  line-height: 1.6;
  color: #666;
}

.app-stores {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.store-button img {
  height: 40px;
  transition: transform 0.2s;
}

.store-button:hover img {
  transform: scale(1.05);
}
